import './polyfill/addEventListener.polyfill.js';
import './polyfill/indexOf.polyfill.js';

window.addEventListener('DOMContentLoaded', function() {
    // Define variables
    var badge_link, iframe, position, width, height, url, style, type;
    var options = {};
    var url_params = '';

    // Get targeted element
    badge_link = document.getElementById('SoMoneyBadge');

    // Options and default values (Sent to server)

    // Get position
    if ((
            position = badge_link.getAttribute('data-position')
        ) !== undefined && ['left', 'right'].indexOf(position) !== -1)
        options.position = position;
    else
        position = options.position = 'right';

    // Get type
    if ((
            type = badge_link.getAttribute('data-type')
        ) !== undefined && ['small', 'medium', 'large'].indexOf(type) !== -1)
        options.type = type;
    else
        type = options.type = 'small';

    // Get width
    width = (
        badge_link.getAttribute('data-width') || '100'
    ).replace('px', '');

    // Define
    if (type === 'small' || type === 'large') height = width * 1.15;
    else height = width * 1.3;

    // Define style
    style = 'top: ' + (
        badge_link.getAttribute('data-top') || '200'
    ).replace('px', '') + 'px;position: fixed;';

    if (position === 'left') style += 'left: 0px;';
    else style += 'right: 0px;';

    // Define GET parameters
    for (var key in options) {
        if (url_params !== '') url_params += '&';
        url_params += key + '=' + options[key];
    }

    // Define API URL
    url = badge_link.href + 'badge/' + (
        badge_link.hasAttribute('data-api-key') ? badge_link.getAttribute('data-api-key') : ''
    ) + '?' + url_params;

    // Create IFrame
    iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'somoney-badge-iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute('width', width + 'px');
    iframe.setAttribute('height', height + 'px');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('style', style);
    badge_link.parentNode.replaceChild(iframe, badge_link);
});
